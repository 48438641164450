import Api from '@/providers/api';

/**
 * Fetch contracts from api.
 */
async function fetchContracts(project_id, filters = {}) {
  const response = await Api().get(`projects/${project_id}/contracts`, {
    params: {
      page: 1,
      per_page: 100,
      ...filters,
    },
  });
  return response.data.data;
}

/**
 * Fetch contract from api.
 */
async function fetchContract(project_id, contract_id) {
  const response = await Api().get(`projects/${project_id}/contracts/${contract_id}`);
  return response.data.data;
}

/**
 * Create a contract.
 */
async function createContract(project_id, data) {
  const response = await Api().post(`projects/${project_id}/contracts`, data);
  return response.data.data;
}

/**
 * Update a contract.
 */
async function updateContract(project_id, contract_id, data) {
  const response = await Api().patch(`projects/${project_id}/contracts/${contract_id}`, data);
  return response.data.data;
}

/**
 * Delete a contract.
 */
async function deleteContract(project_id, contract_id) {
  await Api().delete(`projects/${project_id}/contracts/${contract_id}`);
}

/**
 * Terminate a contract.
 */
async function terminateContract(project_id, contract_id) {
  const response = await Api().post(`projects/${project_id}/contracts/${contract_id}/terminate`);
  return response.data.data;
}

export default function useContracts() {
  return {
    fetchContracts,
    fetchContract,
    createContract,
    updateContract,
    deleteContract,
    terminateContract,
  };
}
