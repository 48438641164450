<template>
  <address class="not-italic">
    <div v-for="(line, i) in lines" :key="i">
      {{ line }}<span v-if="lines.length > i + 1">,</span>
    </div>
    <div v-if="lines.length === 0">
      {{ $tuf('unknown_address') }}
    </div>
  </address>
</template>

<script>
import { computed } from '@vue/composition-api';
import get from 'lodash/get';
import compact from 'lodash/compact';

export default {
  name: 'Address',

  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {

    const fullStreet = computed(() => {
      const street = get(props.address, 'street');

      const houseNumber = compact([
        get(props.address, 'housenumber'),
        get(props.address, 'housenumber_addition'),
      ]).join('').trim();

      return compact([street, houseNumber]).join(' ').trim();
    });

    const cityWithPostal = computed(() => {
      return compact([
        get(props.address, 'postalcode'),
        get(props.address, 'city'),
      ]).join(' ').trim();
    });

    const provinceWithCountry = computed(() => {
      const fields = compact([
        get(props.address, 'province'),
        get(props.address, 'country'),
      ]);
      return fields.join(', ').trim();
    });

    const lines = computed(() => {
      const data = [];

      if (fullStreet.value) {
        data.push(fullStreet.value);
      }
      if (cityWithPostal.value) {
        data.push(cityWithPostal.value);
      }
      if (provinceWithCountry.value) {
        data.push(provinceWithCountry.value);
      }

      return data;
    });

    return {
      lines,
    };
  },
};
</script>
