<template>
  <div class="bg-white p-4 rounded shadow relative">
    <Overlay v-if="loading" class="z-2 flex justify-center items-center bg-white">
      <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
        <LoadingIndicator />
      </div>
    </Overlay>

    <div class="relative z-1">
      <div>
        <span v-if="status" class="inline-block px-1 text-xs rounded-md uppercase whitespace-no-wrap" :class="status.color">{{ status.label }}</span>
      </div>

      <div class="flex flex-wrap justify-between my-4">
        <div>
          <PersonName :person="data.person" class="font-medium" />
          <Address :address="data.asset.address" />
        </div>
        <div class="flex gap-4">
          <div>
            <div class="text-right text-prasset-gray-700 uppercase text-xs">{{ $tuf('start_date') }}</div>
            <div class="text-right">{{ data.start_date || $t('not_available_short') }}</div>
          </div>
          <div>
            <div class="text-right text-prasset-gray-700 uppercase text-xs">{{ $tuf('end_date') }}</div>
            <div class="text-right">{{ data.end_date || $t('not_available_short') }}</div>
          </div>
        </div>
      </div>

      <div v-if="data.media && data.media.length > 0" class="border border-gray-200 rounded overflow-hidden divide-y mb-4">
        <div v-for="file in data.media" :key="file.id" class="flex justify-between items-center hover:bg-gray-100">
          <a href="javascript:void();" @click.prevent="downloadFile(file)" class="block flex-1 px-2 py-1 truncate">{{ file.id }}</a>
          <div class="px-2 py-1 text-prasset-gray-700">{{ file.extension }}</div>
          <div class="px-2 py-1 text-prasset-gray-700">{{ filesize(file.original_size) }}</div>
          <div class="px-2 py-1 border-l border-gray-300 bg-white">
            <button type="button" @click="removeFile(file)" class="button button--small button--clamp button--clear" title="Verwijder bestand">
              <i class="icon-delete text-red-700" />
            </button>
            <a href="javascript:void();" @click.prevent="downloadFile(file)" class="button button--small button--clamp button--clear" title="Download bestand">
              <i class="ri-file-download-line text-green-700" />
            </a>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap justify-between flex-row-reverse">
        <div>
          <button v-if="['IS_CREATED'].includes(data.status)" @click="changeStatus('IS_OPTION')" type="button" class="button button--small button--clear text-prasset-gray-400">
            <i class="ri-archive-line mr-2"></i>
            Onder voorbehoud
          </button>
          <button v-if="['IS_CREATED'].includes(data.status)" @click="changeStatus('IS_PENDING')" type="button" class="button button--small button--clear text-prasset-gray-400">
            <i class="ri-archive-line mr-2"></i>
            In afwachting
          </button>
          <button v-if="['IS_PENDING', 'IS_OPTION'].includes(data.status)" @click="changeStatus('IS_APPROVED')" type="button" class="button button--small button--opague text-prasset-gray-400">
            <i class="ri-check-line mr-2"></i>
            Goedkeuren
          </button>
          <button v-if="['IS_APPROVED'].includes(data.status)" @click="changeStatus('IS_ACTIVE')" type="button" class="button button--small button--opague text-prasset-gray-400">
            <i class="ri-check-line mr-2"></i>
            Activeren
          </button>
        </div>
        <div>
          <button v-if="['IS_PENDING', 'IS_OPTION', 'IS_ACTIVE'].includes(data.status)" @click="terminate" type="button" class="button button--small button--clear text-prasset-gray-400">
            <i class="ri-door-closed-line mr-2"></i>
            Overeenkomst beëndigen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/composition-api';
import Address from '@/components/Address';
import Api, { downloadAs } from '@/providers/api';
import EventBus from '@/eventbus';
import filesize from 'filesize';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import PersonName from '@/components/PersonName';
import useContract from '@/compositions/useContracts';

export default {
  name: 'Contract',

  components: {
    Address,
    LoadingIndicator,
    Overlay,
    PersonName,
  },

  props: {
    contract: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { root, emit }) {
    const { project_id } = root.$route.params;
    const { updateContract, fetchContract, terminateContract } = useContract();

    const state = reactive({
      loading: false,
      data: { ...props.contract },
    });

    const status = computed(() => {
      switch (state.data.status) {
        case 'IS_CREATED':
          return { color: 'bg-gray-200', label: 'nieuw' };
        case 'IS_OPTION':
          return { color: 'bg-purple-200', label: 'onder voorbehoud' };
        case 'IS_PENDING':
          return { color: 'bg-orange-200', label: 'in afwachting' };
        case 'IS_APPROVED':
          return { color: 'bg-blue-200', label: 'goedgekeurd' };
        case 'IS_ACTIVE':
          return { color: 'bg-green-300', label: 'actief' };
        case 'IS_EXPIRED':
          return { color: 'bg-gray-200', label: 'verlopen' };
        case 'IS_ENDED':
          return { color: 'bg-gray-200', label: 'beëindigd' };
      }
      return null;
    });

    async function terminate() {
      EventBus.$emit('confirm:open', {
        title: 'Let op',
        main: `Weet je zeker dat je deze overeenkomst wil beëindigen?`,
        confirmButton: 'Ja, beëindigen',
        cancelButton: 'Annuleren',
        async onConfirm() {
          state.loading = true;
          state.data = await terminateContract(project_id, props.contract.id);
          state.loading = false;
          emit('change', state.data);
        },
      });
    }

    async function changeStatus(status) {
      state.loading = true;
      state.data = await updateContract(project_id, props.contract.id, { status });
      state.loading = false;
      emit('change', state.data);
    }

    function removeFile(file) {
      EventBus.$emit('confirm:open', {
        title: 'Let op',
        main: `Weet je zeker dat je deze bestand wilt verwijderen? Het verwijderen van bestanden kan niet ongedaan worden gemaakt.`,
        confirmButton: 'Ja, verwijderen',
        cancelButton: 'Annuleren',
        async onConfirm() {
          await Api().delete(`projects/${project_id}/media/${file.id}`);
          state.data = await fetchContract(project_id, props.contract.id);
        },
      });
    }

    async function downloadFile(file) {
      await downloadAs(`projects/${project_id}/media/${file.id}/download`, {}, `${file.id}.${file.extension}`);
    }

    return {
      ...toRefs(state),
      status,
      changeStatus,
      removeFile,
      filesize,
      terminate,
      downloadFile,
    };
  },
};
</script>
