<template>
  <div>{{ fullName }}</div>
</template>

<script>
import { computed } from '@vue/composition-api';
import get from 'lodash/get';
import compact from 'lodash/compact';

export default {
  props: {
    person: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const fullName = computed(() => {
      const fields = compact([
        get(props.person, 'firstname'),
        get(props.person, 'infix'),
        get(props.person, 'lastname'),
      ]);
      return fields.join(' ').trim();
    });

    return {
      fullName,
    };
  },
};
</script>
